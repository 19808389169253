/* 공통 메뉴 스타일 */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 52px;
    padding: 0 max(2%, 15px);
    background-color: var(--z-1);
    z-index: 1000;
}

.navbar a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 0.3rem;
    font-size: 1rem;
    color: var(--text-color);
    text-decoration: none;
    user-select: none;
    background-color: var(--z-1);
}

.navbar #all-menu {
    display: flex;
    align-items: center;
    height: 100%;
    transition: transform 0.3s ease-in-out;
}

.navbar #all-menu #settings {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-top: 0;
    border: none;
}

.navbar #language_button select {
    outline: none;
}

#dark_toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 26px;
}

#dark_toggle input {
    display: none;
    user-select: none;
}

.toggle-slider {
    display: flex;
    align-items: center;
    justify-content: baseline;
    cursor: pointer;
    box-sizing: border-box;
    padding: 1px;
    width: 100%;
    height: 100%;
    padding: 2px;
    background-color: #eee;
    border-radius: 25px;
}

.toggle-slider>span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    width: 50%;
    height: 100%;
    transition: 0.2s;
    border-radius: 50%;
    background-color: #777;
    font-size: 90%;
}

#dark_toggle>input:checked+.toggle-slider {
    justify-content: end;
}

#dark_toggle>input:checked+.toggle-slider>span {
    background-color: #2196f3;
}

#langsel {
    padding: 5px 2px;
    border: 1px solid var(--text-color);
    border-radius: 0.6rem;
    background-color: var(--z-2);
    color: var(--text-color);
    cursor: pointer;
    outline: none;
}

#langsel>option {
    padding: 0px;
}

#langsel:hover {
    outline: 4px solid #77777788;
}

#ham-menu,
#close-menu {
    color: var(--text-color);
}

/* 기본 상태 (PC 화면에서는 가로 배치) */
@media (min-width: 769px) {
    .navbar #all-menu {
        transform: none;
        /* 애니메이션 효과 제거 */
    }

    .navbar #all-menu a {
        width: 5em;
    }

    .navbar #all-menu #settings {
        gap: 15px;
        height: 100%;
        margin-left: 15px;
    }
}

/* 모바일 메뉴 (기본적으로 숨김) */
@media (max-width: 768px) {
    .navbar #all-menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 80vw;
        height: 100vh;
        background-color: var(--z-1);
        padding: 60px 20px;
        z-index: 9999;

        /* 초기 상태 (화면 밖으로 이동) */
        transform: translateX(100%);
    }

    .navbar #all-menu a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.3rem;
        height: 3rem;
        padding: 0 10px;
        font-size: 1rem;
        color: var(--text-color);
        text-decoration: none;
        user-select: none;
        background-color: var(--z-1);
    }

    .navbar #all-menu {
        flex-direction: column;
        width: 80%;
    }

    .navbar #all-menu #settings {
        border-top: 1px solid var(--text-color3);
        padding-top: 10px;
        height: max-content;
        width: 80%;
        gap: 10px;
    }

    .navbar #all-menu #settings::before {
        content: 'Setting: ';
        color: var(--text-color);
    }

    /* 메뉴 열기 (슬라이드 인) */
    .navbar #all-menu.open {
        transform: translateX(0);
    }

    /* 닫기 버튼 (모바일에서만 표시) */
    .navbar #close-menu {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 28px;
        cursor: pointer;
    }

    /* 햄버거 버튼 (모바일에서 표시) */
    #ham-menu {
        display: block;
        font-size: 28px;
        cursor: pointer;
    }
}

/* PC 환경에서는 햄버거 버튼 숨김 */
@media (min-width: 769px) {

    #ham-menu,
    #close-menu {
        display: none;
    }
}

@media (pointer: fine) {
    .navbar a:hover {
        background-color: var(--hover-bgc-1);
    }

    #dark_toggle>input:hover+.toggle-slider {
        outline: 4px solid #77777788;
    }

    #language_selector label:hover {
        background-color: var(--hover-bgc-1);
    }

    #language_button:hover {
        cursor: pointer;
        background-color: var(--hover-bgc-1);
    }

    #language_selector label:active {
        background-color: var(--clicked-bgc-1);
    }

    #language:checked+#language_button {
        background-color: var(--clicked-bgc-1);
    }

    #language_selector:hover {
        cursor: default;
    }
}
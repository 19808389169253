* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
}

@keyframes bg {
    0% {
        background: #19dcea;
    }

    100% {
        background: #b22cff;
    }
}

button {
    transition: filter 0.2s ease;
    /* 부드러운 전환 */
}

button:active {
    filter: brightness(0.8);
    /* 밝기를 80%로 줄임 */
}

#spring {
    --season-c: #f08080;
}

#summer {
    --season-c: #84a961;
}

#autumn {
    --season-c: #dc851f;
}

#winter {
    --season-c: #81c3d7;
}

/* AddonDetail 페이지 스타일링 */
.addon-detail-page {
    position: relative;
    top: 52px;
    width: 100%;
    height: calc(100vh - 52px);
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.addon-detail-page>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    background-color: var(--z-1);
    overflow: hidden;
}

.addon-detail-page>#head {
    margin: 80px auto 0px auto;
    padding-bottom: 3rem;
    border-radius: 2.25rem 2.25rem 0 0;
}

.addon-detail-page>#body {
    margin: 0.8rem auto 10rem auto;
    padding-top: 3rem;
    padding-bottom: 7rem;
    border-radius: 0 0 2.25rem 2.25rem;
}

.addon-detail-page>div>section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 0 20px 0px 20px;
}

#preview_image {
    width: 100%;
}

#go_to_back {
    position: sticky;
    top: 0;
    width: 100%;
    margin: 20px 0;
    padding: 10px 0;
    background-color: var(--z-1);
    z-index: 99;
}

#go_to_back>a {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
    color: var(--season-c);
    font-size: 1.1rem;
}

.addon-detail-page header {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    width: 100%;
    height: 128px;
    margin-bottom: 5rem;
    font-size: 2.5em;
    text-align: center;
}

.addon-detail-page header>img {
    width: auto;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.addon-detail-page header>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: left;
}

.addon-detail-page header #main {
    display: flex;
    flex-direction: column;
}

.addon-detail-page header #sub {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    width: 100%;
}

.addon-detail-page header>div h1 {
    font-size: 2.1rem;
    font-weight: 600;
    margin: 0 0 1.6px 0;
    color: var(--text-color);
}

.addon-detail-page header>div h2 {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    color: var(--text-color3);
}

.addon-detail-page header>div a {
    text-decoration: none;
    padding: 4px 14px;
    border-radius: 1rem;
    background-color: var(--season-c);
    /* color: #007bff; */
    color: #FFFFFF;
    font-size: 1.2rem;
    transition: 200ms ease-out;
}

.addon-detail-page header>div a:nth-child(2) {
    border: 1px solid var(--season-c);
    background-color: var(--button-bgc-w);
    color: var(--season-c);
}

#head table {
    border-top: 1px solid #777777;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    border-collapse: collapse;
}

#head table>tr {
    display: flex;
}

#head table>tr:nth-child(1) {
    font-weight: 500;
    font-size: 0.9rem;
}

#head table>tr:nth-child(2) td {
    border-right: 1px dashed var(--text-color3);
}

#head table>tr:nth-child(2) td:last-child {
    border: none
}

#head table td {
    flex: 1;
    color: var(--text-color);
}

.addon-detail-page>div>section>h2 {
    width: 100%;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: left;
    color: var(--text-color);
}

.addon-detail-page #addon_img_carousel {
    width: 100%;
    margin-bottom: 5rem;
    border-radius: 1.3rem;
    overflow: hidden;
}

.addon-detail-page .carousel-control-next:hover::before {
    content: 'Next';
    font-size: 1.2rem;
    font-weight: 600;
}

.addon-detail-page .carousel-control-prev:hover::after {
    content: 'Previous';
    font-size: 1.2rem;
    font-weight: 600;
}

.addon-detail-page #description {
    width: 100%;
    margin-bottom: 5rem;
    color: var(--text-color3);
}

.addon-detail-page ol {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 350px;
    margin: 0;
    padding: 20px 20px 20px 28px;
    border-radius: 1.3rem;
    overflow-y: scroll;
    background-color: var(--color-bgc-1);
    color: var(--text-color2);
    text-align: left;
    scrollbar-width: thin;
}

.addon-detail-page li::marker {
    font-weight: 500;
}

.addon-detail-page #addon-infos {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 6rem;
}

.addon-detail-page #addon-infos p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-color3);
}

.addon-detail-page #addon-infos p>b {
    font-weight: 700;
    color: var(--text-color);
}

.addon-detail-page #lang_info {
    position: relative;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}

.addon-detail-page #download {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    color: var(--text-color);
}

.addon-detail-page #download #term_agree {
    display: flex;
    gap: 3px;
}

.addon-detail-page #download #term_agree>input {
    scale: 1.2;
    cursor: pointer;
}

.addon-detail-page #download #term_agree>label {
    cursor: pointer;
}

.addon-detail-page #download #down_and_sel {
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.addon-detail-page #download #down_and_sel button {
    display: block;
    width: 220px;
    padding: 1rem;
    margin: 20px auto;
    border-radius: 1.78rem;
    font-size: 1.2em;
    cursor: pointer;
    transition: 100ms;
    user-select: none;
}

.addon-detail-page #download #down_and_sel button>span {
    vertical-align: middle;
    margin-right: 0.16rem;
}

.addon-detail-page #download_button {
    border: 1px solid var(--season-c);
    background-color: var(--button-bgc-w);
    color: var(--season-c);
}

.addon-detail-page #versions_button {
    border: 1px solid #777777;
    background-color: #777777;
    color: #FFFFFF;
}

#version_select_modal {
    position: fixed;
    top: 52px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #000000aa;
}

#version_select_modal>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 800px;
    height: 60%;
    padding: 1rem 0 2rem 0;
    background-color: var(--z-2);
    border-radius: 1.3rem;
    overflow: hidden;
    /* 넘치는 콘텐츠 숨김 */
}

#version_select_modal>div>div {
    display: flex;
    flex-direction: column;
    width: 80%;
    color: var(--text-color);
}

#version_select_modal>div>h3 {
    margin-bottom: 1rem;
}

#version_select_modal>div>table {
    width: 80%;
    overflow-y: scroll;
}

#version_select_modal #moal_close {
    border: 0;
    width: 80%;
    padding: 4px 20px;
    margin-top: 1rem;
    font-size: 1.2rem;
    background-color: var(--z-4);
    color: var(--text-color);
}

.version_download {
    border: 0;
    background-color: transparent;
    color: var(--text-color);
}



@media (max-width: 768px) {

    .addon-detail-page header {
        gap: 10px;
        height: 98px;
        margin-bottom: 3rem;
    }

    .addon-detail-page>div {
        width: 100%;
    }

    .addon-detail-page #go_to_back {
        width: 100%;
    }

    .addon-detail-page>div>section {
        width: 100%;
        padding: 0px 10px 0px 10px;
    }

    .addon-detail-page #download #down_and_sel {
        width: 100%;
        justify-content: space-between;
    }

    .addon-detail-page #download #down_and_sel button {
        width: fit-content;
    }
}

@media (pointer: fine) {

    @media (hover: none) {
        .addon-detail-page #download_button:hover {
            filter: brightness(0.8);
        }

        .addon-detail-page #download_button>button:hover {
            filter: brightness(0.8);
        }

        .addon-detail-page header>div a:hover {
            filter: brightness(0.8);
        }

        .addon-detail-page #versions_button:hover {
            filter: brightness(0.8);
        }
    }

    @media (hover: hover) {
        .addon-detail-page #download_button:hover {
            background-color: var(--season-c);
            color: #ffffff;
        }

        .addon-detail-page #download_button>button:hover {
            background-color: #fff;
        }

        .addon-detail-page header>div a:hover {
            transform: scale(1.05);
        }

        /* .addon-detail-page #addon_img_carousel:hover>.carousel-indicators {
            background-color: #93939363;
        } */

        .addon-detail-page #versions_button:hover {
            border: 1px solid #777777;
            background-color: #fff;
            color: #777777;
        }
    }
}
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,300,0,0");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans+KR:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

* {
    font-family: 'Inter', 'NanumSquare', 'Noto Sans KR', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 180ms;
    transition: color 300ms;
    transition: background-color 300ms;
}

@media (pointer: coarse) {
    * {
        transition: transform 100ms;
        transition: filter 100ms;
    }
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
    user-select: none;
}

.material-symbols-rounded {
    font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 0,
        'opsz' 24;
    user-select: none;
}

:root {
    --page-background-color: #eeedef;
    --text-color: #000;
    /* 기본텍스트 */
    --text-color2: #000;
    --text-color3: #777;
    --z-0: #fff;
    --z-1: #fdfdfd;
    --z-2: #fbfefb;
    --z-3: #f9f9f9;
    --z-4: #e7e7e5;
    --select-bgc: #f7f7f7;
    --border-1: #ddd;
    --hover-bgc-1: #ddd;
    --hover-bgc-2: #93add3;
    --color-bgc-1: #ddf4d6;
    --button-bgc-w: #ffffff;
    --button-tc-g: #84a961;
    --button-bgc-b: #007bff;
    --main-content-bgc: rgba(255, 255, 255, 0.8);
    --selection-color: #0088ff;
}

body.dark-mode {
    --page-background-color: #262b32;
    --text-color: #e9e9e9;
    --text-color2: #909c88;
    --text-color3: #a8a8a8;
    --z-0: #0d0c0e;
    --z-1: #222;
    --z-2: #2f3233;
    --z-3: #3b4554;
    --z-4: #565652;
    --select-bgc: #e4e4e4;
    --border-1: none;
    --hover-bgc-1: #36363b;
    --hover-bgc-2: #171d21;
    --color-bgc-1: #333333;
    --button-bgc-w: transparent;
    /* 2e2d32 -> transparent */
    --button-tc-g: #84a961;
    --button-bgc-b: #007bff;
    --main-content-bgc: rgba(41, 41, 44, 0.8);
    --selection-color: #57616c;
}

::selection {
    background-color: var(--selection-color);
    color: #f3f3f3;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

@keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        letter-spacing: normal;
        opacity: 1;
    }
}



/* HomePage */
.home-page {
    width: 100%;
}

.main-viewport {
    position: relative;
    width: 100%;
    height: calc(100vh - 52px);
    margin-top: 52px;
    overflow-x: hidden;
}

/* VideoOverlay */
.video-container {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background-video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #FFF;
    text-align: center;
}

.overlay h1 {
    font-size: 2.8rem;
    font-weight: 650;
    font-family: "Poppins", "Noto Sans KR", system-ui;
    word-break: keep-all;
    width: 100%;
    -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation-delay: 200ms;
}

.overlay a {
    padding: 8px 10px;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: #007bff;
    background-color: #e4e4e4a0;
}

.overlay a span {
    font-size: 1.2rem;
    vertical-align: middle;
}

/* MainContent */
.main-content {
    position: absolute;
    top: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 100%;
    min-height: 100vh;
    padding: 5rem 0 15rem 0;
    border-top-left-radius: 3vw;
    border-top-right-radius: 3vw;
    background: linear-gradient(180deg, var(--main-content-bgc) 0%, var(--main-content-bgc) 100%);
    backdrop-filter: blur(30px);
    box-shadow: 0px 0px 31px -3px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.sections {
    display: flex;
    flex-direction: column;
    width: 80%;
    /* min-width: 450px; */
}

.sections>h2 {
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Poppins';
    color: var(--text-color);
}

#menu>div {
    display: flex;
    flex-direction: row;
    gap: 10%;
}

#menu a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    flex: 1;
    padding: 20px;
    border-radius: 15px;
    color: var(--text-color);
    border: 0.5px solid #bbb;
    transition: transform 180ms cubic-bezier(0.550, 0.085, 0.680, 0.530);
}


#menu a>span {
    font-size: 2.5rem;
    margin-bottom: 12px;
}

#menu a>h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 3px;
}

#menu a>h4 {
    font-size: 0.9rem;
    font-weight: 500;
}

#addons>div {
    display: flex;
    gap: 34px;
    width: 100%;
    height: 352px;
    overflow-x: auto;
    overflow-y: hidden;
}

#addons>div .addons_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 210px;
    padding: 14px;
    border-radius: 0.9rem;
    background-color: var(--main-content-bgc);
    text-decoration: none;
}

#addons>div .addons_button>.addons_info {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    width: 100%;
    gap: 1px;
}

#addons .addon-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 182px;
    height: 182px;
    border-radius: 0.9rem;
    object-fit: cover;
    font-size: 100px;
}

#addons .addons_button span {
    font-size: 100px;
    transition: 300ms;
}

#addons .addons_button h3 {
    font-size: 1.5rem;
    text-decoration: none;
    margin: 0;
    padding: 0;
}

#addons .addons_button h4 {
    font-size: 0.7rem;
    text-decoration: none;
    margin: 0;
    padding: 0;
}

#addons .addons_button h4>span {
    font-size: 0.7rem;
    vertical-align: middle;
    margin-right: 1.3px;
}

#news>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#news>div article {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 45%;
}

#news article h3 {
    font-size: 1rem;
    margin-bottom: 12px;
    color: var(--text-color3);
}

#news .article-main {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    border-radius: 1rem;
    overflow: hidden;
    user-select: none;
}

#news #new_addon img {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
}

#news #new_addon a {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 5;
    color: #000;
    text-decoration: none;
}

#news #new_addon a>span {
    font-size: 3rem;
    font-weight: 500;
    padding: 0.2rem;
    border-radius: 0.4rem;
    background-color: #fff;
}


#news #new_addon>h3 {
    text-align: left;
}

#news #new_video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
}

#social>div {
    display: flex;
    gap: 4rem;
}

#social>div>a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    width: fit-content;
    color: var(--text-color);
}

#social>div>a>img {
    object-fit: cover;
    width: 96px;
    height: 96px;
}

#social>div>a>p,
#social>div>a>p>span {
    font-size: 1rem;
    vertical-align: middle;
}


@media (pointer: coarse) {
    .overlay a:hover {
        transform: scale(1.25);
    }

    #menu a:active {
        filter: brightness(0.8);
    }

    #addons>div .addons_button:active {
        filter: brightness(0.8);
    }

    #news #new_addon a:active {
        filter: brightness(0.8);
    }
}

@media (pointer: fine) {
    .overlay a:hover {
        text-decoration: underline;
    }

    #menu a:hover {
        /* background-color: var(--hover-bgc-2); */
        border-color: #007bff;
        transform: scale(1.05);
    }

    #addons>div .addons_button:hover>.addon-icon span {
        transform: rotate(-275deg);
    }

    #addons>div .addons_button:hover {
        border: 1px solid #007bff;
    }

    #news #new_addon a:hover {
        background-color: #333333cc;
    }

    #news #new_addon a:hover::after {
        content: 'DOWNLOAD';
        color: #FFF;
    }
}

@media (max-width: 768px) {
    .sections {
        width: 100%;
        padding: 0 5px;
    }

    #menu>div {
        flex-direction: column;
        gap: 15px;
    }

    #menu a {
        max-width: 100%;
        padding: 10px 0;
    }

    #news>div {
        flex-direction: column;
        gap: 5rem;
    }

    #news>div article {
        width: 100%;
    }

    #news #new_addon>h3 {
        text-align: right;
    }
}
.not-found-page {
    position: relative;
    top: 52px;
    width: 100%;
    padding: 15px 0 0 15px;
    color: var(--text-color);
}

.not-found-page>h1 {
    font-size: 28pt;
    margin-bottom: 20px;
}

.not-found-page>h1>del {
    color: lightgray;
}

.not-found-page>p {
    font-size: 13pt;
    margin-bottom: 2px;
}

.not-found-page>a {
    font-size: 13pt;
    margin-bottom: 50px;
}

.no-server-response-page {
    position: relative;
    top: 52px;
    padding: 20px;
    color: var(--text-color);
}

.no-server-response-page>p {
    margin: 3rem 0 5px 0;
    font-weight: 600;
}

.no-server-response-page ol {
    padding: 0px 20px;
}